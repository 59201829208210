$(function () {
  //newsカルーセル
  var newsSwiper = new Swiper(".js-news", {
    slidesPerView: 'auto',
    spaceBetween: 28,
    navigation: {
      nextEl: ".p-news-slider .swiper-button-next",
      prevEl: ".p-news-slider .swiper-button-prev",
    },
  });

  //新着タブ
  var infoSwiper = new Swiper(".js-info", {
    slidesPerView: 1,
    allowTouchMove: false,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    initialSlide: 1,
  });
  $('.js-infoBtns a').click(function () {
    infoSwiper.slideTo($(this).attr('href'))
    return false;
  })

  //カテゴリタブ
  var catSwiper = [];
  $('.js-cat').each(function (index, el) {
    catSwiper[index] = new Swiper('.js-cat' + index, {
      slidesPerView: 1,
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
    });
    $('.js-catBtn' + index + ' a').click(function () {
      $('.js-catBtn' + index + ' a').removeClass('is-active')
      $(this).addClass('is-active')
      catSwiper[index].slideTo($(this).attr('href'))
      return false;
    })
  })
})